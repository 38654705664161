import type { ObjectValues } from '@vrstar/lib/browser';
import { IdTokenResult as FirebaseIdTokenResult } from 'firebase/auth';

export const roles = {
  BankAdmin: 'bank_admin',
  ContentAdmin: 'content_admin',
  ContentFeedAgent: 'content_feed_agent',
  OrganizerAgent: 'organizer_agent',
  OrganizerManager: 'organizer_manager',
  PartnerAgent: 'partner_agent',
  PartnerManager: 'partner_manager',
  SystemAdmin: 'system_admin',
  VideocallAgent: 'videocall_agent',
  RealEstateAgent: 'real_estate_agent',
} as const;

export type RolesType = ObjectValues<typeof roles>;

/**
 * Reflects the actual values stored in Firebase Authentication
 */
export interface IdTokenResult extends FirebaseIdTokenResult {
  claims: {
    bic: string | undefined;
    roles: RolesType[] | undefined;
    partnerIds: string[] | undefined;
  }
}
