// src/array.ts
var arrayCompareFunction = (key, order = "asc") => (a, b) => {
  if (typeof key !== "string") {
    return 0;
  }
  let varA = key.split(".").reduce((o, i) => o[i], a);
  if (typeof varA === "string") varA = varA.toUpperCase();
  let varB = key.split(".").reduce((o, i) => o[i], b);
  if (typeof varB === "string") varB = varB.toUpperCase();
  if (!varA && !varB) return 0;
  if (typeof varA === "string" && typeof varB === "string") {
    const stringComparison = Intl.Collator().compare(varA, varB);
    return order === "desc" ? stringComparison * -1 : stringComparison;
  }
  if (typeof varA === "number" && typeof varB === "number") {
    let comparison = 0;
    if (!varA) comparison = -1;
    if (!varB) comparison = 1;
    if (varA > varB) comparison = 1;
    else if (varA < varB) comparison = -1;
    return order === "desc" ? comparison * -1 : comparison;
  }
  return 0;
};
function sortArray(array, key, order = "asc") {
  return array.sort(arrayCompareFunction(key, order));
}

// src/helpers.ts
var generateUniqueRandomString = (length) => {
  const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const generateRandomChar = () => {
    const randomIndex = Math.floor(Math.random() * charset.length);
    return charset.charAt(randomIndex);
  };
  return Array.from({ length }, generateRandomChar).join("");
};

// src/types/firebase-errors.ts
var errorMessageCodes = {
  EMAIL_EXISTS: "EMAIL_EXISTS",
  PHONE_EXISTS: "PHONE_EXISTS",
  INVALID_PHOTO_URL: "INVALID_PHOTO_URL",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  TOKEN_EXPIRED: "TOKEN_EXPIRED",
  TOKEN_REVOKED: "TOKEN_REVOKED",
  TOKEN_INVALID: "TOKEN_INVALID",
  NOTIFICATION_TOKEN_NOT_SET: "NOTIFICATION_TOKEN_NOT_SET",
  UNAUTHORIZED: "UNAUTHORIZED",
  ENTITY_IN_USE: "ENTITY_IN_USE",
  CANNOT_DELETE_LAST_BANK_ADMIN: "CANNOT_DELETE_LAST_BANK_ADMIN"
};

// src/types/import.ts
var importTypes = ["event"];
var importStates = ["draft", "running", "failed", "succeeded", "undone"];

// src/types/index.ts
var contentCategories = [
  "events",
  "partners",
  "estate",
  "feed",
  "seniors"
];

// src/definitions/attachment-types.ts
var imageTypes = [
  "image/jpeg",
  "image/png",
  "image/webp",
  "image/gif"
];
var videoTypes = [
  "video/mp4",
  "video/webm"
];
var applicationTypes = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.oasis.opendocument.text",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.oasis.opendocument.presentation",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.oasis.opendocument.spreadsheet"
];
var relatedResourceTypes = [
  "event",
  "feed",
  "offer",
  "profile",
  "bank"
];
var attachmentTypes = { imageTypes, videoTypes, applicationTypes };

// src/definitions/record-states.ts
var RecordStates = ["DRAFT", "REVIEW", "ACTIVE"];

// src/definitions/content-feeds.ts
var ContentFeedType = /* @__PURE__ */ ((ContentFeedType2) => {
  ContentFeedType2["RSS"] = "rss";
  ContentFeedType2["BANK"] = "bank";
  ContentFeedType2["VRSTAR"] = "vrstar";
  return ContentFeedType2;
})(ContentFeedType || {});
var ContentFeedContentType = /* @__PURE__ */ ((ContentFeedContentType2) => {
  ContentFeedContentType2["FILE"] = "file";
  ContentFeedContentType2["ARTICLE"] = "article";
  ContentFeedContentType2["LINK"] = "link";
  return ContentFeedContentType2;
})(ContentFeedContentType || {});

// src/definitions/content-categories.ts
var ContentCategoryType = /* @__PURE__ */ ((ContentCategoryType2) => {
  ContentCategoryType2["ESTATE"] = "estate";
  ContentCategoryType2["PARTNERS"] = "partners";
  ContentCategoryType2["EVENTS"] = "events";
  ContentCategoryType2["FEED"] = "feed";
  ContentCategoryType2["SENIORS"] = "seniors";
  ContentCategoryType2["CLUBS"] = "clubs";
  return ContentCategoryType2;
})(ContentCategoryType || {});

// src/definitions/entity-history.ts
var SupportedHistoryEntityType = /* @__PURE__ */ ((SupportedHistoryEntityType2) => {
  SupportedHistoryEntityType2["EVENT"] = "EVENT";
  SupportedHistoryEntityType2["ORGANIZER"] = "ORGANIZER";
  SupportedHistoryEntityType2["VENUE"] = "VENUE";
  return SupportedHistoryEntityType2;
})(SupportedHistoryEntityType || {});
var ActionType = /* @__PURE__ */ ((ActionType2) => {
  ActionType2["CREATE"] = "CREATE";
  ActionType2["UPDATE"] = "UPDATE";
  ActionType2["DELETE"] = "DELETE";
  return ActionType2;
})(ActionType || {});

// src/definitions/poi.ts
var POI_CATEGORY = {
  RAIFFEISEN_MARKET: "raiffeisen_market"
};

// src/i18n.ts
import i18next from "i18next";

// src/lang/de.json
var de_default = {
  recurringSettings: {
    at: "am",
    genericEvery: "Alle",
    longVersion: {
      and: "und",
      lastOccurrence: "Letztes mal am",
      total: "Insgesamt {{times}} mal"
    },
    recurringSingularUnit: {
      day: "T\xE4glich",
      month: "Monatlich",
      week: "W\xF6chentlich",
      year: "J\xE4hrlich"
    },
    times: "mal",
    units: {
      day: "Tage",
      month: "Monate",
      week: "Wochen",
      year: "Jahre"
    },
    until: "bis"
  }
};

// src/lang/en.json
var en_default = {
  recurringSettings: {
    at: "at",
    genericEvery: "Every",
    longVersion: {
      and: "and",
      lastOccurrence: "Last time on",
      total: "Total {{times}} times"
    },
    recurringSingularUnit: {
      day: "Daily",
      month: "Monthly",
      week: "Weekly",
      year: "Yearly"
    },
    times: "times",
    units: {
      day: "Days",
      month: "Months",
      week: "Weeks",
      year: "Years"
    },
    until: "until"
  }
};

// src/i18n.ts
var SUPPORTED_LANGUAGE = {
  DE: "de",
  EN: "en"
};
i18next.init({
  lng: SUPPORTED_LANGUAGE.DE,
  supportedLngs: [SUPPORTED_LANGUAGE.DE, SUPPORTED_LANGUAGE.EN],
  fallbackLng: SUPPORTED_LANGUAGE.EN,
  debug: false,
  returnNull: false,
  preload: [SUPPORTED_LANGUAGE.DE, SUPPORTED_LANGUAGE.EN],
  defaultNS: "lib",
  resources: {
    de: {
      lib: de_default
    },
    en: {
      lib: en_default
    }
  }
});
var { t, exists: te } = i18next;
function getT(lng) {
  return i18next.getFixedT(lng);
}
function addTranslations(namespace, resources) {
  Object.keys(resources).forEach((lng) => {
    i18next.addResourceBundle(lng, namespace, resources[lng]);
  });
}
async function changeLanguage(lng) {
  await i18next.changeLanguage(lng);
}

// src/definitions/recurring-settings.ts
import { DateTime, Info } from "luxon";
var RECURRING_INTERVAL_UNIT = {
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year"
};
var RECURRING_END_TYPE = {
  NEVER: "never",
  ON_DATE: "on_date",
  REPEATS: "repeats"
};
var recurringIntervalUnits = ["day", "week", "month", "year"];
var recurringEndTypes = ["never", "on_date", "repeats"];
function getRecurrenceSettingsLabel(settings, lng = SUPPORTED_LANGUAGE.DE) {
  const t2 = getT(lng);
  let result = "";
  if (settings.interval.amount === 1) {
    result += t2(`lib:recurringSettings.recurringSingularUnit.${settings.interval.unit}`);
  } else {
    result += `${t2("lib:recurringSettings:genericEvery")} ${settings.interval.amount} ${t2(`lib:recurringSettings:units.${settings.interval.unit}`)}`;
  }
  if (settings.interval.unit === RECURRING_INTERVAL_UNIT.WEEK && settings.interval.days) {
    const weekdayLabels = Info.weekdays("short", { locale: lng });
    const days = settings.interval.days.map((dayOfWeek) => weekdayLabels[dayOfWeek - 1]).join(", ");
    result += ` ${t2("lib:recurringSettings.at")} ${days}`;
  }
  if (settings.end.type === RECURRING_END_TYPE.REPEATS && typeof settings.end.value === "number") {
    result += `, ${settings.end.value} ${t2("lib:recurringSettings.times")}`;
  }
  if (settings.end.type === RECURRING_END_TYPE.ON_DATE && typeof settings.end.value === "string") {
    result += `, ${t2("lib:recurringSettings.until")} ${DateTime.fromISO(settings.end.value).toFormat("dd. MMM. yyyy")}`;
  }
  return result;
}
function getRecurrenceSettingsLongLabel(settings, occurrences, lng = SUPPORTED_LANGUAGE.DE) {
  const t2 = getT(lng);
  let result = "";
  if (settings.interval.amount === 1) {
    result += t2(`lib:recurringSettings.recurringSingularUnit.${settings.interval.unit}`);
  } else {
    result += `${t2("lib:recurringSettings:genericEvery")} ${settings.interval.amount} ${t2(`lib:recurringSettings:units.${settings.interval.unit}`)}`;
  }
  if (settings.interval.unit === RECURRING_INTERVAL_UNIT.WEEK && settings.interval.days) {
    const weekdayLabels = Info.weekdays("long", { locale: lng });
    const days = settings.interval.days.map((dayOfWeek) => weekdayLabels[dayOfWeek - 1]);
    const daysString = (() => {
      if (days.length > 1) {
        const lastBothDays = days.splice(-2).join(` ${t2("lib:recurringSettings.longVersion.and")} `);
        return [...days, lastBothDays].join(", ");
      }
      return days.join(", ");
    })();
    result += ` ${t2("lib:recurringSettings.at")} ${daysString}`;
  }
  if (settings.end.type === RECURRING_END_TYPE.REPEATS && typeof settings.end.value === "number") {
    const lastOccurrence = [...occurrences].pop();
    if (lastOccurrence) {
      result += `. ${t2("lib:recurringSettings.longVersion.lastOccurrence")} ${DateTime.fromISO(lastOccurrence.from).toFormat("dd. MMMM yyyy")}.`;
    } else {
      result += `. ${t2("lib:recurringSettings.longVersion.total", { times: settings.end.value })}`;
    }
  }
  if (settings.end.type === RECURRING_END_TYPE.ON_DATE && typeof settings.end.value === "string") {
    result += `. ${t2("lib:recurringSettings.longVersion.lastOccurrence")} ${DateTime.fromISO(settings.end.value).toFormat("dd. MMMM yyyy")}.`;
  }
  return result;
}
function generateRecurringDates(settings, startDate, endDate) {
  const start = DateTime.fromJSDate(startDate);
  const end = endDate ? DateTime.fromJSDate(endDate) : void 0;
  const diff = end ? end.diff(start) : void 0;
  let shouldContinue = true;
  const rows = [];
  let latestStart = start;
  function endDateReached(from) {
    if (settings.end.type === RECURRING_END_TYPE.ON_DATE && typeof settings.end.value === "string" && DateTime.fromISO(settings.end.value).endOf("day") <= from) {
      return true;
    }
    if (settings.end.type === RECURRING_END_TYPE.NEVER && DateTime.now().plus({ year: 2 }).endOf("day") <= from) {
      return true;
    }
    return false;
  }
  function repeatsReached() {
    if (settings.end.type === RECURRING_END_TYPE.REPEATS && typeof settings.end.value === "number") {
      const maxRepeats = settings.end.value - 1;
      if (maxRepeats <= rows.length) {
        return true;
      }
    }
    return false;
  }
  function getToDate(from) {
    if (!end || !diff) {
      return from;
    }
    return from.plus(diff);
  }
  function handleOccurrence(from) {
    if (endDateReached(from)) {
      return false;
    }
    let customFrom = from;
    if (settings.customTimes?.perWeekday && settings.customTimes.perWeekday[from.weekday]) {
      const startTimeForWeekday = settings.customTimes.perWeekday[from.weekday];
      const startTimeForWeekdayParts = startTimeForWeekday.split(":").map((part) => parseInt(part));
      customFrom = customFrom.set({
        hour: startTimeForWeekdayParts[0],
        minute: startTimeForWeekdayParts[1]
      });
    }
    const fromAsDateString = customFrom.toISODate();
    if (settings.customTimes?.perDate && fromAsDateString && settings.customTimes.perDate[fromAsDateString]) {
      const startTimeForDate = settings.customTimes.perDate[fromAsDateString];
      const startTimeForWeekdayParts = startTimeForDate.split(":").map((part) => parseInt(part));
      customFrom = customFrom.set({
        hour: startTimeForWeekdayParts[0],
        minute: startTimeForWeekdayParts[1],
        second: startTimeForWeekdayParts[2] ?? 0
      });
    }
    rows.push({ from: customFrom, to: getToDate(customFrom) });
    latestStart = from;
    return !repeatsReached();
  }
  function handleOccurrencesPerWeek(from) {
    if (settings.interval.unit === RECURRING_INTERVAL_UNIT.WEEK && settings.interval.days) {
      for (const dayOfWeek of settings.interval.days) {
        const forWeekday = from.set({
          weekday: dayOfWeek
        });
        if (forWeekday > start) {
          shouldContinue = handleOccurrence(forWeekday);
          if (!shouldContinue) {
            return false;
          }
        }
      }
    }
    return true;
  }
  function getNextOccurrence() {
    let from = latestStart.plus({ [settings.interval.unit]: settings.interval.amount });
    if (settings.interval.unit === RECURRING_INTERVAL_UNIT.WEEK) {
      return handleOccurrencesPerWeek(from);
    }
    if ([RECURRING_INTERVAL_UNIT.MONTH, RECURRING_INTERVAL_UNIT.YEAR].includes(settings.interval.unit)) {
      let multiplier = 2;
      while (from.day !== latestStart.day) {
        from = latestStart.plus({ [settings.interval.unit]: settings.interval.amount * multiplier });
        multiplier += 1;
      }
    }
    return handleOccurrence(from);
  }
  if (settings.end.type === RECURRING_END_TYPE.REPEATS && typeof settings.end?.value === "number" && settings.end.value <= 1) {
    return [];
  }
  if (settings.interval.unit === RECURRING_INTERVAL_UNIT.WEEK) {
    shouldContinue = handleOccurrencesPerWeek(start);
  }
  if (shouldContinue) {
    do {
      shouldContinue = getNextOccurrence();
    } while (shouldContinue);
  }
  return rows.map((row) => ({
    from: row.from.toUTC().toISO(),
    to: row.to.toUTC().toISO()
  }));
}

// src/definitions/notifications.ts
var NotificationTopics = /* @__PURE__ */ ((NotificationTopics2) => {
  NotificationTopics2["ESTATE_ALL"] = "estate_all";
  NotificationTopics2["ESTATE_DISCOUNT"] = "estate_discount";
  NotificationTopics2["ESTATE_SEARCH"] = "estate_search";
  NotificationTopics2["EVENTS_SEARCH"] = "events_search";
  NotificationTopics2["FEED_BANK"] = "feed_bank";
  return NotificationTopics2;
})(NotificationTopics || {});
var NotificationTopicResourceType = /* @__PURE__ */ ((NotificationTopicResourceType2) => {
  NotificationTopicResourceType2["event"] = "event";
  NotificationTopicResourceType2["estate"] = "estate";
  NotificationTopicResourceType2["partner"] = "partner";
  NotificationTopicResourceType2["feed"] = "feed";
  return NotificationTopicResourceType2;
})(NotificationTopicResourceType || {});

// src/definitions/country-codes.ts
var countryCodes = [
  "AT",
  // Austria
  "BE",
  // Belgium
  "CZ",
  // Czechia
  "DK",
  // Denmark
  "FR",
  // France
  "DE",
  // Germany
  "LU",
  // Luxembourg
  "NL",
  // Netherlands
  "PL",
  // Poland
  "LI",
  // Lichtenstein
  "CH",
  // Switzerland
  "IT"
  // Italy
];

// src/definitions/zip-code-masks.ts
var zipCodeMasks = {
  AT: "9999",
  BE: "9999",
  CH: "9999",
  CZ: "999 99",
  DE: "99999",
  DK: "9999",
  FR: "99999",
  LI: "9999",
  LU: "9999",
  NL: "9999aa",
  PL: "99-999"
};

// src/definitions/index.ts
var AttachmentTypes = attachmentTypes;
var CountryCodes = countryCodes;
var ZipCodeMasks = zipCodeMasks;
var PartnerOfferValidityTypes = ["day", "week", "month"];
var MetricTypes = ["views"];
var MetricResourceTypes = ["event", "partner", "venue", "partner_offer", "estate", "content_feed_record", "organizer"];

export {
  sortArray,
  generateUniqueRandomString,
  errorMessageCodes,
  importTypes,
  importStates,
  contentCategories,
  relatedResourceTypes,
  RecordStates,
  ContentFeedType,
  ContentFeedContentType,
  ContentCategoryType,
  SupportedHistoryEntityType,
  ActionType,
  POI_CATEGORY,
  SUPPORTED_LANGUAGE,
  t,
  te,
  getT,
  addTranslations,
  changeLanguage,
  RECURRING_INTERVAL_UNIT,
  RECURRING_END_TYPE,
  recurringIntervalUnits,
  recurringEndTypes,
  getRecurrenceSettingsLabel,
  getRecurrenceSettingsLongLabel,
  generateRecurringDates,
  NotificationTopics,
  NotificationTopicResourceType,
  AttachmentTypes,
  CountryCodes,
  ZipCodeMasks,
  PartnerOfferValidityTypes,
  MetricTypes,
  MetricResourceTypes
};
