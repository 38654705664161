<script setup lang="ts">
import * as Sentry from '@sentry/vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import { computed, ref } from 'vue';
import { toTypedSchema } from '@vee-validate/zod';
import { useI18n } from 'vue-i18n';
import { useField, useForm } from 'vee-validate';
import { useCurrentUser } from 'vuefire';
import * as zod from 'zod';
import { useUserStore } from '@/store/userStore';
import { storeToRefs } from 'pinia';
import { coreApi } from '@/api/core';
import { useRouter } from 'vue-router';
import { routeNames } from '@/router/types';
import { useModalStore } from '@/store/modalStore';
import { auth } from '@/firebase';

const user = useCurrentUser();

const store = useUserStore();
const { hasPasswordChange } = storeToRefs(store);

const isPasswordRevealed = ref(false);

const passwordLength = 6;

const { t } = useI18n();

const passwordSchema = toTypedSchema(
  zod.string().nonempty()
    .min(passwordLength)
    .default(''),
);

const {
  value: newPwd, errorMessage: newPwdError, meta: newPwdMeta, handleBlur: handleNewPwdBlur,
} = useField('newPassword', passwordSchema);

const passwordConfSchema = toTypedSchema(
  zod.string().nonempty()
    .min(passwordLength)
    .refine(((value) => value === newPwd.value), { message: t('validation_errors.pwd_no_match') })
    .default(''),
);

const {
  value: newPwdConfirmation,
  errorMessage: newPwdConfirmationError,
  handleBlur: handleNewPwdConfirmationBlur,
  meta: newPwdConfirmationMeta,
} = useField('newPasswordConfirmation', passwordConfSchema);

const areAllFieldsTouched = computed(() => newPwdMeta.touched && newPwdConfirmationMeta.touched);

const { handleSubmit, resetForm } = useForm();

const firebaseErrorMessage = ref(false);

const router = useRouter();

const { userHasChangedPassword } = storeToRefs(useModalStore());

const hasSubmittedPassword = ref(false);

const onSubmit = handleSubmit(async () => {
  firebaseErrorMessage.value = false;
  if (!user.value || !newPwd.value) return;

  try {
    await coreApi.user.updatePassword.mutate({ newPassword: newPwd.value });
    resetForm();
    hasSubmittedPassword.value = true;
    userHasChangedPassword.value = true;
    await auth.signOut();
    router.push({ name: routeNames.Login });
  } catch (e) {
    Sentry.captureException(e);
    firebaseErrorMessage.value = true;
  }
});
</script>

<template>
  <Dialog
    v-if="hasPasswordChange && !hasSubmittedPassword"
    :visible="true"
    :draggable="false"
    :closable="false"
    modal
    :header="t('global_modals.password_change_required')"
    class="w-90vw lg:w-40vw">
    <div class="flex flex-col gap-space-4">
      <p class="text-left">
        {{ $t('global_modals.choose_password') }}
      </p>
      <small
        v-if="firebaseErrorMessage"
        id="text-error"
        class="p-error text-center">
        {{ $t('firebase_error.wrong_credentials') }}
      </small>
    </div>
    <template #footer>
      <form
        class="flex flex-col gap-space-6"
        @submit.prevent="onSubmit">
        <span class="flex flex-col gap-space-4 text-left">
          <label for="new-password">{{ $t('forms.new_password') }}</label>
          <InputText
            id="new-password"
            v-model.trim="newPwd"
            :class="{ 'p-invalid': newPwdError && newPwdMeta.touched || firebaseErrorMessage }"
            :placeholder="$t('forms.placeholder.password')"
            :type="isPasswordRevealed ? 'text' : 'password'"
            @blur="handleNewPwdBlur" />
          <span
            v-if="newPwdError && newPwdMeta.touched"
            class="p-error text-left">
            {{ newPwdError }}
          </span>
        </span>

        <span class="flex flex-col gap-space-4 text-left">
          <label for="password-confirmation">
            {{ $t('forms.confirm_password') }}
          </label>
          <InputText
            id="password-confirmation"
            v-model.trim="newPwdConfirmation"
            :placeholder="$t('forms.placeholder.password')"
            :class="{ 'p-invalid': newPwdConfirmationError && newPwdConfirmationMeta.touched || firebaseErrorMessage }"
            :type="isPasswordRevealed ? 'text' : 'password'"
            @blur="handleNewPwdConfirmationBlur" />
          <span
            v-if="newPwdConfirmationError && newPwdConfirmationMeta.touched"
            class="p-error text-left">
            {{ newPwdConfirmationError }}
          </span>
        </span>

        <div class="flex justify-between">
          <div class="flex items-center">
            <Checkbox
              v-model="isPasswordRevealed"
              name="reveal-password"
              input-id="reveal-password"
              binary />

            <label
              for="reveal-password"
              class="ml-2">
              {{ $t('forms.reveal_password') }}
            </label>
          </div>

          <Button
            :disabled="!areAllFieldsTouched"
            type="submit"
            class="w-content text-center font-bold"
            :label="$t('generic.confirm')" />
        </div>
      </form>
    </template>
  </Dialog>
</template>
