import { routeNames } from '@/router/types';
import { getCurrentUser } from 'vuefire';
import { createRouter, createWebHistory } from 'vue-router';
import type { RouteLocationGeneric, RouteMeta as VueRouteMeta } from 'vue-router';
import { useUserStore } from '@/store/userStore';
import { routes } from '@/router/routes';
import { lastValueFrom, first } from 'rxjs';
import { appReady$ } from '@/utils/app-state';
import type { RolesType } from '@/types/User';
import { useModalStore } from '@/store/modalStore';
import type { User } from '@/utils/auth';

export const router = createRouter({
  history: createWebHistory(),
  routes,
});

interface PermissionGuardContext {
  meta: VueRouteMeta;
  user?: User;
}

declare module 'vue-router' {
  interface RouteMeta {
    requireAuth?: boolean;
    roles?: RolesType[];
    permissionGuards?: {
      afterRoleCheck?: (ctx: PermissionGuardContext) => Promise<boolean>;
    };
  }
}

router.beforeEach((to, from, next) => {
  // wait until the app is ready before navigating
  lastValueFrom(appReady$.pipe(first((v) => v))).then(async () => {
    const user = await getCurrentUser();

    if (to.name === routeNames.Login) {
      if (user) {
        next({ name: routeNames.Home });
        return;
      }
    }

    if (to.meta.requireAuth) {
      if (!user) {
        next({ name: routeNames.Login });
        if (to.name !== routeNames.Home) {
          const store = useModalStore();
          store.afterLoginRoutePath = to;
        }

        return;
      }
    }

    if (to.meta) {
      const { accessGranted } = useUserStore();

      if (!(await accessGranted(to.meta))) {
        next(from);
        return;
      }
    }

    next();
  });
});

export const redirectToFirstAccessibleChildren = async (route: RouteLocationGeneric) => {
  const lastMatch = [...route.matched].pop();

  if (lastMatch?.children) {
    const { accessGranted } = useUserStore();

    const getFirstAccessibleChild = async () => {
      for await (const child of lastMatch.children) {
        // TODO remove skipping the home route as soon as we have a home/dashboard page
        if (child.name === routeNames.Home) {
          // eslint-disable-next-line no-continue
          continue;
        }

        const accessCheckResult = await accessGranted(child.meta);

        if (accessCheckResult) {
          return child;
        }
      }

      return null;
    };

    const dest = await getFirstAccessibleChild();
    if (dest) {
      if (dest.redirect && typeof dest.redirect !== 'function') {
        router.replace(dest.redirect);
      } else {
        router.replace(dest);
      }
    }
  }
};
